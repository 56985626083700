.one-notification {
  .title {
    color: #3cb3f7;
  }
  .body {
    white-space: break-spaces;
    font-size: 14px;
  }
  .date {
    font-size: 12px;
    color: rgb(81, 81, 81);
  }
}
.not-seen {
  background-color: aliceblue !important;
}

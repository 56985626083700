@use "../abstracts/colors" as *;
@use "../abstracts/index" as *;
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  
}

body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Roboto", "SST Roman", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
}

#root {
  height: 100vh;
}

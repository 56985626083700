.container {
  margin: 10px 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.input-conatiner-home {
  margin: 10px 0;
}
.sessions-statistics {
  .header-live-sessions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.box {
  background-color: white;
  border-radius: 10px;
  padding: 12px;
  .box-title {
    color: #2ba7df;
    font-weight: 700;
    font-size: 18px;
  }
  .box-title-underlined {
    color: #2ba7df;
    font-weight: 700;
    font-size: 18px;
    border-bottom: 2px solid;
  }
}
.btn-container-aside {
  display: flex;
  justify-content: end;
}
.btn-container {
  .btn {
    height: 35px;
    width: auto;
    border-radius: 4px;
    button {
      height: 100%;
      width: 100%;
      font-size: 15px;
      padding: 5px 10px;
      font-weight: 500;
      border: none;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .btn-submit {
    button {
      color: white;
      background-color: #40bbf7;
    }
  }
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th,
.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  width: 33%;
}
.rbc-agenda-view table.rbc-agenda-table {
  border: none;
  margin: 0;
}

Table {
  -webkit-border-horizontal-spacing: 0;
  -webkit-border-vertical-spacing: 0;
  width: 100%;
  margin: 10px 0;
  table-layout: fixed;

  thead {
    tr {
      background-color: rgb(241 241 246);
      font-size: 16px;
      color: #ababb0;

      th:first-child {
        border-radius: 10px 0 0 0;
      }
      th:last-child {
        border-radius: 0 10px 0 0;
      }
    }
  }
  th,
  td {
    text-align: start;
    border-collapse: collapse;
    text-align: center !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  tr {
    height: 27px;
    font-weight: 500;
    font-size: 16px;
    th,
    td {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  tbody {
    padding: 0;
    margin: 0;
    tr {
      &:last-child {
        td:first-child {
          border-radius: 0 0 0 10px;
        }
        td:last-child {
          border-radius: 0 0 10px 0;
        }
      }
      &:hover {
        background-color: #f9f9f9;
      }
    }
  }
}

a {
  cursor: pointer;
  text-decoration: none;
}
.icon-styles {
  height: 20px !important;
  width: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.label-color {
  padding: 0 0 0 10px;
  font-size: 1rem;
}
button {
  cursor: pointer;
}
.close-popup {
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background-color: #e6e6e6;
  Button {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    min-width: 24px;
    color: rgb(160, 160, 160);
    svg {
      height: 17px;
    }
  }
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  z-index: 0 !important;
}

.sessions-statistics
  .filter-container
  .date-filter
  .rs-picker-default
  .rs-picker-toggle.rs-btn-lg,
.MuiOutlinedInput-root {
  border-radius: 10px !important;
}
.paginate-table {
  padding-left: 0 !important;
  padding-right: 0 !important;
  & > div {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.MuiTablePagination-actions {
  margin-left: 5px !important;
}
.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  margin-right: 8px !important;
  margin-left: 2px !important;
}
.table-scroll-container {
  width: 100%;
}
.table-scroll {
  min-width: 1200px;
}
@media screen and (max-width: 1244px) {
  .table-scroll-container {
    overflow-x: scroll;
  }
}
.input-component-container label {
  z-index: 0;
}

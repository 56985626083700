.pop-up {
  .inputs-container {
    margin: 15px;
    gap: 10px 30px;
    display: flex;
    flex-direction: column;
    .submit-part {
      display: flex;
      justify-content: end;
    }
  }
}

.chat-container {
  background-color: white;
  height: 700px;
  width: 100%;
  min-width: 600px;
  border-radius: 10px;
  .chat-header {
    height: 8%;
    border-radius: 10px 10px 0 0;
    background-color: #2389f0;
    color: white;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .chat-body {
    height: 82%;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: scroll;
    padding: 5px 10px;
    .message {
      max-width: 90%;
      display: flex;
      flex-direction: column;
      .top {
        display: flex;
        align-items: flex-end;
        gap: 5px;
        .name-text {
          .text {
            padding: 5px 10px;
            min-height: 37px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        span {
          font-weight: bold;
          color: grey;
          font-size: 10px;
          white-space: nowrap;
        }
      }
    }
    .not-mine {
      margin: 3px auto 3px 0;
      align-items: flex-end;
      .top {
        margin: 3px auto 3px 0;
        .userpic {
          height: 37px;
          width: 37px;
          border-radius: 50%;
          object-fit: cover;
        }
        .text {
          border-radius: 10px 10px 10px 0;
          background-color: #d3d3d3;
        }
      }
    }
    .mine {
      margin: 3px 0px 3px auto;
      align-items: flex-start;
      .top {
        margin: 3px 0px 3px auto;
        flex-direction: row-reverse;
        .userpic {
          display: none;
        }
        .name-text {
          span {
            display: none;
          }
        }
        .text {
          border-radius: 10px 10px 0 10px;
          color: white;
          background-color: #40bbf7;
        }
      }
    }
    .no-messages {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: grey;
    }
  }
  .chat-footer {
    border-radius: 0 0 10px 10px;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(241 241 246);
    .chat-controllers {
      width: 90%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      Button {
        min-width: 30px;
        height: 30px;
        width: 30px;
        color: rgb(160, 160, 160);
        svg {
          color: #34a6f4;
        }
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .chat-container {
    height: 85vh;
    width: 100%;
    min-width: 100%;
  }
}

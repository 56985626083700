.MuiBox-root .css-1gsv261 {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}
.MuiBox-root .css-19kzrtu {
  padding: 0px;
}
.notif-box {
  margin: 0px;
  width: 522px;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-58%, 3.3%) !important;
}
@media screen and (max-width: 545px) {
  .notif-box {
    width: 365px;
    transform: translate(-38%, 3.3%) !important;
  }
}
.notif-scroll {
  max-height: 80vh;
  overflow-y: scroll;
}

// File: App.scss
// This file contains all styles related to the site/application.
/* STYLES GO HERE */

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-width: 4px;
  scrollbar-color: #2897f3 #e5edf7;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
  z-index: 120;
}

*::-webkit-scrollbar-track {
  background: #e5edf7;
}

*::-webkit-scrollbar-thumb {
  background-color: #2897f3;
  border-radius: 0;
  border: 0px solid #e5edf7;
}

.rbc-button-link {
  border: none;
  color: #434343;
  background-color: transparent;
}
.rbc-time-column,
.rbc-toolbar-label {
  color: #434343;
}
.rbc-time-header,
.rbc-overflowing {
  margin: 0 !important;
}
.rbc-event-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.rbc-day-slot .rbc-event,
.rbc-event {
  border: 1px solid gray;
  font-weight: bold;
}
.rbc-time-gutter .rbc-time-column {
  width: 68.3906px;
}
.rbc-day-slot .rbc-events-container {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0px;
  top: 0;
}
.rbc-row-content {
  z-index: 0;
}
@media screen and (max-width: 680px) {
  .rbc-toolbar {
    display: flex;
    flex-direction: column-reverse;
  }
}

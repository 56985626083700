.edit-user,
.edit-password,
.delete-account {
  .inputs-container {
    margin: 5px 20px;
    gap: 10px 30px;
    display: flex;
    flex-direction: column;
  }
  .delete-inputs-container {
    margin: 5px 20px;
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .submit-part {
    display: flex;
    justify-content: end;
  }
}
.user-avatar-container {
  width: 75px;
  border-radius: 50%;
  .user-avatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.user-avatar-part {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left-part {
    display: flex;
    align-items: center;
    gap: 5%;
  }
  .right-part {
    display: flex;
    align-items: center;
    gap: 5%;
  }
}

.input-component-container {
  // margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  label {
    font-weight: 700;
    color: #434343;
  }
  .room-information {
    display: flex;
    flex-direction: row;
    gap: 5%;
    .room-input-container {
      select {
        color: #434343;
        -webkit-appearance: none;
        -moz-appearance: none;
        -ms-appearance: none;
        appearance: none;
        outline: 0;
        box-shadow: none;
        border-radius: 0.25em;
        border: 1px solid #a8a8a8;
        background: transparent;
        background-image: none;
        width: 230px;
        height: 43px;
        font-size: 18px;
        padding: 0 0.5em;
        cursor: pointer;
      }
      select:focus {
        border: 2px solid #40bbf7;
      }
      select::-ms-expand {
        display: none;
      }
      & {
        position: relative;
        display: flex;
        width: 230px;
        height: 45px;
        line-height: 3;
        border-radius: 0.25em;
      }
      &::after {
        content: "\25BC";
        position: absolute;
        top: 0;
        right: 0;
        padding: 0em 1em;
        background: #40bbf7;
        cursor: pointer;
        pointer-events: none;
        transition: 0.25s all ease;
        color: #eeeeee;
      }
    }
    .room-informations {
      max-width: 200px;
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 0 1.5em 0 0;
      .one-information {
        height: 40px !important;
        width: 40px !important;
        border-radius: 8px;
        border: solid 1px #adadad;
        box-shadow: 1px 1px 2px #c5c5c5;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          filter: invert(27%) sepia(0%) saturate(0%) hue-rotate(147deg)
            brightness(92%) contrast(93%);
          height: 75%;
        }
        .special-img {
          height: 55%;
        }
      }
    }
  }
}
.hidden {
  display: none;
}

.overLay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: hsl(0deg 0% 0% / 70%);
  z-index: 5;
}
.form {
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 7;
  top: 50%;
  left: 50%;
  border-radius: 10px;
  background: transparent;
  max-width: 600px;
  min-width: 25%;
}
@media screen and (max-width: 800px) {
  .form {
    min-width: 70%;
    max-width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .form {
    min-width: 100%;
    max-width: 100%;
  }
}

.show-reservation {
  font-size: 16px;
  .title {
    font-weight: 700;
  }
  .edit-btns {
    position: absolute;
    right: 15px;
    bottom: 12px;

    display: flex;
    justify-content: center;
    gap: 10px;
    Button {
      height: 24px;
      width: 24px;
      min-width: 24px;
      background-color: #e6e6e6;
      border-radius: 50%;
      color: rgb(160, 160, 160);
      svg {
        height: 17px;
      }
    }
  }
}

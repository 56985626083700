.confirm-delete-modal {
  & > .MuiDialog-container {
    & > .MuiPaper-root {
      min-width: unset;
      & > .confirm-delete-modal-wrapper {
        & > .modal-confirm {
          color: #636363;
          max-width: 400px;
          & > .modal-content {
            padding: 20px;
            border-radius: 5px;
            border: none;
            text-align: center;
            font-size: 14px;
            & > .MuiDialogTitle-root {
              position: relative;
              & > .MuiTypography-root {
                & > button {
                  width: 20px;
                  height: 20px;
                  & > svg {
                    display: flex;
                    justify-content: center;
                    width: 18px;
                    height: 18px;
                    color: #7b7b7b;
                  }
                }
                & > .icon-box {
                  width: 80px;
                  height: 80px;
                  margin: 0 auto;
                  border-radius: 50%;
                  z-index: 9;
                  text-align: center;
                  border: 3px solid rgb(211, 47, 47);
                  & > svg {
                    color: rgb(211, 47, 47);
                    font-size: 46px;
                    display: inline-block;
                    margin-top: 17px;
                  }
                }
                & > .modal-title {
                  text-align: center;
                  font-size: 26px;
                  margin: 30px 0 -10px;
                }
                & > .close {
                  position: absolute;
                  top: -10px;
                  right: -10px;
                  & > svg {
                    cursor: pointer;
                  }
                }
              }
            }
            & > .MuiDialogContent-root {
              padding: 0;
              & > .modal-text {
                color: #999;
              }
              & > .modal-actions {
                border: none;
                text-align: center;
                border-radius: 5px;
                font-size: 13px;
                padding: 10px 0px;
                & > button,
                button:active {
                  color: white;
                  border-radius: 4px;
                  text-decoration: none;
                  transition: all 0.4s;
                  line-height: normal;
                  font-weight: 600;
                  min-width: 120px;
                  border: none;
                  min-height: 40px;
                  border-radius: 3px;
                  margin: 0 5px;
                }
                .cancel-btn {
                  background-color: #999;
                }
                .delete-btn {
                  background-color: rgb(211, 47, 47);
                }
              }
            }
          }
        }
      }
    }
  }
}

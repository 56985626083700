* {
  font-family: "Roboto";
}
.main-layout {
  .header {
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 64px;
    background-color: white;
    padding: 0 25px 0 5px;
  }
  .headerleft {
    display: flex;
    align-items: center;
    flex: auto;

    .menu {
      width: 38px;
      height: 38px;
      background-color: #efefef;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 13px;
      cursor: pointer;
      img {
        width: 75%;
        filter: invert(60%) sepia(0%) saturate(8%) hue-rotate(199deg)
          brightness(102%) contrast(85%);
      }
    }

    .logo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px auto 5px 15px;
      .logotext {
        color: #2ba7df;
        font-size: 19px;
        font-weight: 500;
        margin-left: 10px;
      }
    }
    @media screen and (max-width: 550px) {
      .logo {
        .logotext {
          display: none;
        }
      }
    }
    .searchbar {
      display: flex;
      flex: auto;
      justify-content: flex-start;
      border: solid 1px #d6d6da;
      min-width: 400px;
      height: 35px;
      border-radius: 7px;
      img {
        width: 15px;
        margin: 0 12px 0 16px;
      }
      input {
        border: none;
        height: 100%;
        width: 88%;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: #a2a2a2;
        }
      }
    }
  }
  .headerright {
    display: flex;
    align-items: center;

    .wallet {
      display: flex;
      margin-left: 58px;
      img {
        width: 30px;
        filter: invert(36%) sepia(86%) saturate(381%) hue-rotate(167deg)
          brightness(93%) contrast(88%);
      }
      .wallettext {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        margin-top: 5px;
        .title {
          color: #a2a2a7;
          font-size: 12px;
        }
        .subtitle {
          color: #18698d;
          font-size: 17px;
          font-weight: 500;
        }
      }
    }
    .user {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: auto 5px;
      padding: 4px 8px;
      border-radius: 5px;
      &:hover {
        background-color: #eeeeee;
      }
      .userpic {
        height: 37px;
        width: 37px;
        border-radius: 50%;
        object-fit: cover;
      }
      .usertext {
        margin-left: 8px;
        .title {
          filter: invert(57%) sepia(67%) saturate(500%) hue-rotate(156deg)
            brightness(91%) contrast(92%);
          font-weight: 600;
          font-size: 14px;
        }
        .subtitle {
          color: #a2a2a7;
          font-size: 12px;
        }
      }
      .chevron {
        height: 12px;
        margin: 8px;
      }
    }
    .log-out,
    .notifications-container {
      position: relative;
      margin: auto 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 42px;
      padding: 4px 8px;
      border-radius: 5px;
      &:hover {
        background-color: #eeeeee;
      }
      svg {
        filter: invert(57%) sepia(67%) saturate(500%) hue-rotate(156deg)
          brightness(91%) contrast(92%);
        width: 25px;
        height: 25px;
        cursor: pointer;
      }

      .notif-count {
        position: absolute;
        top: 7px;
        right: -5px;
        background-color: #f50057;
        border-radius: 50%;
        width: 20px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          font-size: 11px;
          font-weight: bold;
          color: white;
        }
      }
    }
  }

  .page-body {
    display: flex;
    flex-direction: row;
    height: 90vh;

    .sidebar {
      position: fixed;
      z-index: 2;
      margin: 64px 0 0 0;
      width: 228px;
      height: 100vh;
      background: linear-gradient(45deg, #2389f0, #40bbf7);
      box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
      transition: 0.5s all ease;
      -moz-transition: 0.5s all ease; /* Firefox 4 */
      -webkit-transition: 0.5s all ease; /* Safari and Chrome */
      -o-transition: 0.5s all ease; /* Opera */
      -ms-transition: 0.5s all ease; /* Explorer 10 */
      .sidebar-btns-container {
        margin: 36px 20px;

        .sidebar-btn-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-radius: 8px;

          a {
            text-decoration: none;

            .sidebar-btn {
              height: 42px;
              width: 190px;
              display: flex;
              align-items: center;
              svg,
              img {
                width: 27px;
                height: 27px;
                margin: 10px 12px;
              }

              span {
                font-weight: 700;
                font-size: 15px;
              }
            }
          }
        }
      }
      .unselected-btn {
        a {
          color: white;

          img {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(61deg)
              brightness(101%) contrast(101%);
          }
        }
      }
      .selected-btn {
        background-color: white;
        a {
          color: #3870a3;

          img {
            filter: invert(35%) sepia(61%) saturate(527%) hue-rotate(167deg)
              brightness(98%) contrast(87%);
          }
        }
      }
    }
    @media screen and (max-width: 1388px) {
      .sidebar {
        display: none;
        transition: all 0.5s ease-in;
      }
    }
    .smallsidebar {
      position: fixed;
      margin-top: 64px;
      z-index: 2;
      width: 120px;
      height: 100%;
      background: transparent linear-gradient(180deg, #40bcf9, #1e87f0) 0 0
        no-repeat padding-box;
      padding: 0 7px;
      transition: 0.5s all ease;
      -moz-transition: 0.5s all ease; /* Firefox 4 */
      -webkit-transition: 0.5s all ease; /* Safari and Chrome */
      -o-transition: 0.5s all ease; /* Opera */
      -ms-transition: 0.5s all ease; /* Explorer 10 */
      .sidebar-btns-container {
        align-items: center;
        display: flex;
        margin: 36px 0;
        flex-direction: column;
        align-items: center;

        .sidebar-btn-container {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          border-radius: 8px;

          a {
            text-decoration: none;

            .sidebar-btn {
              display: flex;
              justify-content: center;
              flex-direction: column;
              align-items: center;
              padding: 7px 5px;
              min-width: 96px;
              min-height: 70px;

              img {
                width: 30px;
                height: 30px;
              }
              span {
                font-weight: 700;
                font-size: 12px;
              }
            }
          }
        }
      }
      .unselected-btn {
        a {
          color: white;

          img {
            filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(61deg)
              brightness(101%) contrast(101%);
          }
        }
      }
      .selected-btn {
        background-color: white;
        a {
          color: #3870a3;

          img {
            filter: invert(35%) sepia(61%) saturate(527%) hue-rotate(167deg)
              brightness(98%) contrast(87%);
          }
        }
      }
    }
  }
  .body-container {
    margin-top: 64px;
    transition: 0.5s all ease;
    -moz-transition: 0.5s all ease; /* Firefox 4 */
    -webkit-transition: 0.5s all ease; /* Safari and Chrome */
    -o-transition: 0.5s all ease; /* Opera */
    -ms-transition: 0.5s all ease; /* Explorer 10 */
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #f8f8fd;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
  }
  .body-conatiner-margin-one {
    margin-left: 228px;
  }
  .body-conatiner-margin-two {
    margin-left: 120px;
  }
  @media screen and (max-width: 1388px) {
    .sidebar {
      display: none;
      transition: 0.5s all ease;
      -moz-transition: 0.5s all ease; /* Firefox 4 */
      -webkit-transition: 0.5s all ease; /* Safari and Chrome */
      -o-transition: 0.5s all ease; /* Opera */
      -ms-transition: 0.5s all ease; /* Explorer 10 */
    }
    .body-conatiner-margin-one {
      margin-left: 0;
    }
  }
  @media screen and (max-width: 1115px) {
    .body-conatiner-margin-two {
      margin-left: 0;
    }
  }
}
.link {
  display: flex;
  justify-content: center;
  align-items: center;
  & span {
    text-decoration: none;
    color: #727272;
  }
}

.css-l3d94t-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  top: 50px !important;
  right: 40px !important;
  width: 160px !important;
}

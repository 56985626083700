@import "rsuite/dist/rsuite.css";
.sessions-statistics {
  .header-live-sessions {
    .more-btn-right {
      cursor: pointer;
    }
  }
  table thead tr th {
    color: #434343;
  }
  .actions-btn-container-table {
    display: flex;
    justify-content: center;
    gap: 5%;
    Button {
      height: 30px;
      width: 30px;
      min-width: 30px;
      border-radius: 50%;
      color: rgb(160, 160, 160);
      svg {
        height: 20px;
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    color: #a2a2a7;
    margin: 13px 0;
    .pagination-table-lines {
      form {
        label {
          font-weight: 700;
          font-size: 14px;
        }
        select {
          border: none;
          color: #a2a2a7;
          font-weight: 700;
          font-size: 14px;
          cursor: pointer;
        }
      }
    }
    .pagination-table-pages {
      font-weight: 700;
      font-size: 14px;
    }
    .previous-page-btn,
    .next-page-btn {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      img {
        height: 15px;
        filter: invert(81%) sepia(8%) saturate(136%) hue-rotate(201deg)
          brightness(81%) contrast(82%);
      }
    }
  }
  .block {
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 1%;
    row-gap: 10px;
  }
  @media screen and (max-width: 1388px) {
    .block {
      flex-flow: row wrap;
    }
  }
  .hidden {
    display: none;
    transition: all 0.5s ease-in;
  }
  .filter-container {
    background: #ebebf39e;
    padding: 10px;
    margin: 10px 0;
    z-index: 0;
    position: relative;
    border-radius: 10px;
    .filter {
      width: auto;
      border-radius: 10px;
      background-color: white;
    }
    .search-filter {
      width: 100%;
      min-width: 250px;
    }
    .date-filter {
      .rs-picker-default .rs-picker-toggle.rs-btn-lg {
        padding-top: 16px;
        padding-bottom: 16px;
      }
      .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
      .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-clean,
      .rs-picker-date .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret,
      .rs-picker-daterange .rs-picker-toggle.rs-btn-lg .rs-picker-toggle-caret {
        top: 16px;
      }
      .rs-picker-default .rs-picker-toggle {
        background-color: white;
        border: 1px solid #acacb1;
        &:hover {
          border: 1px solid black;
        }
        &:focus {
          border: 2px solid #1976d2;
        }
        box-shadow: none;
        -webkit-box-shadow: none;
      }
    }
    .room-filter1,
    .date-filter1,
    .user-filter {
      width: 32.6%;
      & > * {
        width: 100%;
      }
    }
    @media screen and (max-width: 1162px) {
      .room-filter1,
      .date-filter1,
      .user-filter {
        width: 100%;
      }
    }
  }
  .filter-btns-container {
    display: flex;
    justify-content: end;
    .filter-btn {
      display: flex;
      gap: 5px;
      color: #40bbf7;
    }
  }
}
.user-td {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 10px;
  .user-pic-table img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    object-fit: cover;
  }
}
.sort-btn {
  position: relative;
  top: 4px;
}
.th-btn-container {
  display: flex;
  min-height: 64px;
  justify-content: center;
  align-items: center;
  & > svg {
    height: 17px;
    width: 20px;
  }
  cursor: pointer;
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: transparent;
  & > img {
    animation: logo-rotation 1s ease infinite;
    height: 80px;
    border-radius: 50%;
  }
}

@keyframes logo-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

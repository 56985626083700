.notfound-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .notfound-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    .status {
      font-size: 18em;
    }
    .title {
      font-size: 3em;
    }
    span {
      font-size: 1em;

      a {
        color: #868686;
      }
    }
  }
}

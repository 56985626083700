.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 150px 15px;
  color: #212121;
}
.login-box {
  height: 100%;
  display: flex;
  flex-direction: row;
  background: #fff;
  box-shadow: 0 3px 8px rgb(0 0 0 / 24%);
}
.login-info {
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  background-image: url(../../assets/images/shapes.7d8f7fc8.png),
    linear-gradient(135deg, #2ba7df, #007bff);
  background-size: cover;
  font-size: 14px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  text-align: center;
  img {
    margin: 25px 20px;
    width: 140px;
  }
  .login-description {
    .greating {
      color: #fff;
      font-size: 30px;
      margin: 10px auto;
    }
    .description {
      color: #fff;
      font-size: 14px;
      margin: 10px auto;
    }
  }
}
@media screen and (max-width: 775px) {
  .login-info {
    display: none;
  }
}
.login-card {
  font-size: 14px;
  padding: 24px;
  min-width: 750px;
  min-height: 460px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  .card-title {
    margin: 20px auto;
    font-size: 25px;
    font-weight: 500;
  }
  .form-conatiner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .input-container {
      border: 2px solid transparent;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 4px;
      padding: 4px;
      margin: 8px 0;
      min-height: 47px;
      width: 60%;
      height: 50px;
      background-color: #f8f9fa;
      display: flex;

      .input {
        background-color: #f8f9fa;
        border: 2px solid transparent;
        font-size: 14px;
        border-radius: 4px;
        padding: 4px;
        margin: 8px 0;
        width: 100%;
        height: 100%;
        display: flex;

        &:focus {
          border: 3px solid #2ba7df;
        }
      }
    }
    button {
      background: transparent;
      font-size: 15px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 4px;
      letter-spacing: 0.02857em;
      text-transform: uppercase;
      color: #6c757d;
      padding: 6px 12px;
      font-weight: 400;
      border-color: #6c757d;
      margin: 10px 0;
      height: 40px;
      cursor: pointer;
      &:hover {
        background: #6c757d;
        color: #fff;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      }
    }
  }
  .contact {
    margin: 20px 0 0 0;
    display: flex;
    height: 50px;
    justify-content: center;
    gap: 10px;
    align-items: center;
    .img-container img {
      height: 16px;
      margin-top: 5px;
    }
  }
}
.title-login {
  margin: 0 5px;
}
